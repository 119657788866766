<template>
    <sdPageHeader title="Transport Reports"> </sdPageHeader>
    <Main>
        <a-row :gutter="15">
            <a-col class="w-100" :md="24">
                <sdCards title="Filters ">
                    <a-form :model="filterForm" layout="vertical">
                        <a-row :gutter="25">
                            <a-col :md="6" :xs="6">
                                <a-form-item label="Transport Companie" name="partnerId">
                                    <a-select v-if="partnerApi.length" v-model:value="filterForm.partnerId"
                                        placeholder="Transport Companies" show-search style="width: 100%"
                                        :options="partnerData" :not-found-content="partnerFetching ? undefined : null
                                            " :filter-option="false" @search="(value) => searchPartner(value)">
                                        <template v-if="partnerFetching" #notFoundContent>
                                            <a-spin size="small" />
                                        </template>
                                    </a-select>
                                    <a-select v-else v-model:value="filterForm.partnerId" placeholder="Transport Companies">
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item name="placeOfDelivery" label="Place of Delivery">
                                    <a-input :picker="'start'" v-model:value="filterForm.placeOfDelivery"
                                        :style="{ width: '100%' }"  />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item name="date" label="Date From">
                                    <a-date-picker :picker="'start'" v-model:value="filterForm.dateStart"
                                        :style="{ width: '100%' }" format="DD/MM/YYYY" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item name="date" label="Date To">
                                    <a-date-picker :picker="'start'" v-model:value="filterForm.dateEnd"
                                        :style="{ width: '100%' }" format="DD/MM/YYYY" />
                                </a-form-item>
                            </a-col>

                            <a-col :span="6" class="button-container">
                                <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary"
                                    @click="onFilter()">
                                    <span>Filter</span>
                                </a-button>
                            </a-col>
                            <a-col :span="6" class="button-container">
                                <a-button class="ant-btn-lg" @click="clearFilters()" danger>
                                    <span>Clear Filters</span>
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                </sdCards>
            </a-col>
        </a-row>
        <a-row :gutter="15">
            <a-col class="w-100" :md="24">
                <sdCards title="Report List">
                    <div>
                        <TableWrapper class="table-responsive">
                            <a-table :columns="columns" :dataSource="reportData" :loading="isLoading"
                                :pagination="pagination" @change="onHandleTableChange">
                            </a-table>
                        </TableWrapper>
                    </div>
                </sdCards>
            </a-col>
        </a-row>
        <a-modal v-model:visible="visible" :title="subject" :footer="false">
            <div v-html="htmlMessage"></div>
        </a-modal>
    </Main>
</template>

<script>
import { DataService } from "@/config/dataService/dataService";
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import { Main, TableWrapper } from "../../styled";
import { useStore } from "vuex";
import moment from "moment";
import usePartnerSearch from '@/composable/usePartnerSearch';

const columns = [
    {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
    },
    {
        title: "Product Description",
        dataIndex: "productDescription",
        key: "productDescription",
    },
    {
        title: "Transport Companies",
        dataIndex: "partnerNames",
        key: "partnerNames",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Message",
        dataIndex: "showMessage",
        key: "showMessage",
    },
];
const TransportList = defineComponent({
    name: "TransportList",
    components: {
        Main,
        TableWrapper,
    },

    setup() {
        const { state } = useStore();

        var reportData = ref([]);
        var isLoading = ref(false);
        const sorted = ref({
            columnKey: 'date',
            order: 'desc',
        });
        const pagination = ref({
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
            orderBy: sorted.value.columnKey,
            orderByDirection: sorted.value.order,
            total: 0,
            sorter: sorted.value,
        });

        // #endregion

        // #region filters
        var searchForm = reactive({
            layout: "vertical",
            label: "",
            code: "",
        });
        const searchFormRef = ref([]);
        const htmlMessage = ref("");
        const subject = ref("");
        const visible = ref(false);
        const partnerApi = computed(() => state.partnerApi.data);

        const showMessage = (rapportSubject, message) => {
            subject.value = rapportSubject;
            htmlMessage.value = message;
            visible.value = true;
        };
        async function transportReportGetAllData() {
            try {
                isLoading.value = true;
                const query = await DataService.get(
                    `api/TransportRequest?PageSize=${pagination.value.pageSize}&PageNumber=${pagination.value.current}&dateFrom=${filterForm.dateFrom}&dateTo=${filterForm.dateTo}&partnerId=${filterForm.partnerId}&placeOfDelivery=${filterForm.placeOfDelivery}`
                );
                reportData.value = query.data.map(item => {
                    return {
                        ...item,
                        key: item.id,
                        partnerNames: item.partners.map(partner => partner.name).join(', '),
                        date: moment(item.created).format('YYYY/MM/DD'),
                        showMessage: (
                            <span style={{ cursor: 'pointer', color: '#78B943' }}
                                onClick={() => showMessage(item.subject, item.message)}>Show message</span>
                        ),
                    };
                });

                pagination.value.total = query.recordsFiltered ?? query.recordsTotal;
                isLoading.value = false;
            } catch (err) {
                isLoading.value = false;
            }
        }
        const onHandleTableChange = (tablePagination, filters, sorter) => {
            sorted.value = sorter;
            pagination.value.current = tablePagination.current;
            pagination.value.orderBy = sorted.value.columnKey ?? "";
            pagination.value.orderByDirection = sorted.value.order ?? "";
            pagination.value.sorter = sorter;
            pagination.value.pageSize = tablePagination.pageSize;
        };

        /// filters 
        var filterForm = reactive({
            dateFrom: "",
            dateTo: "",
            placeOfDelivery: "",
            partnerId: "",
        });

        const onFilter = () => {
            const sorter = {
                columnKey: "date",
                order: "descend",
            };

            sorted.value = sorter;
            pagination.value.current = 1;
            pagination.value.orderBy = sorted.value.columnKey ?? "date";
            pagination.value.orderByDirection = sorted.value.order ?? "descend";
            pagination.value.sorter = sorter;
            pagination.value.pageSize = 10;
            filterForm.dateFrom = filterForm.dateStart ? moment(filterForm.dateStart).format('YYYY/MM/DD') : "";
            filterForm.dateTo = filterForm.dateEnd ? moment(filterForm.dateEnd).format('YYYY/MM/DD') : "";
            transportReportGetAllData();

        }
        const clearFilters = () => {
            filterForm.dateFrom = "";
            filterForm.dateTo = "";
            filterForm.placeOfDelivery = "";
            filterForm.partnerId = "";
            onFilter();
        }

        onMounted(() => {
            transportReportGetAllData();
        });
        // #end region

        const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
            filterForm.partnerId
        );

        return {
            onHandleTableChange,
            pagination,
            subject,
            htmlMessage,
            visible,
            showMessage,
            columns,
            searchForm,
            searchFormRef,
            reportData,
            isLoading,
            partnerApi,
            onFilter,
            clearFilters,
            partnerData,
            searchPartner,
            partnerFetching,
            filterForm,
        };
    },
});
export default TransportList;
</script>
<style lang="scss" scoped>
.custom,
.ant-calendar-picker-input.ant-input {
    height: 38px;
}

.button-container {
    align-self: center !important;

    .ant-btn {
        height: 38px;
        width: 100%;
    }
}
</style>